<link rel="stylesheet" href="/aragon.css" />

<div style="background-image: url('assets/images/background.png')">
    <div class="container pb-5">
        <div class="row justify-content-center align-items-center vh-100 d-flex">
            <div class="col-lg-6 col-md-7">
                <div class="card border-0 mb-0" style="border-radius: 15px">
                    <div class="card-body px-lg-5 rounded-2" style="background-color: #f7fafc">
                        <div class="text-center mb-4">
                            <h3 style="color: #fa914d; font-weight: 700">Teacher Signup</h3>
                        </div>

                        <!-- Progress Indicator -->
                        <div class="progress-container mb-4">
                            <div class="progress-bar">
                                <div class="progress" [style.width.%]="progress"></div>
                            </div>
                            <div class="progress-steps">
                                <div [class.active]="currentStep >= 1" aria-hidden="true" (click)="setStep(1)" class="dropzone">
                                    <div class="step-number">1</div>
                                    <div class="step-label">Basic Info</div>
                                </div>
                                <div [class.active]="currentStep >= 2" aria-hidden="true" (click)="setStep(2)" class="dropzone">
                                    <div class="step-number">2</div>
                                    <div class="step-label">Contact Info</div>
                                </div>
                                <div [class.active]="currentStep >= 3" aria-hidden="true" (click)="setStep(3)" class="dropzone">
                                    <div class="step-number">3</div>
                                    <div class="step-label">Login Info</div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion" id="accordionBasicContact">
                            <!-- Basic Info -->
                            <div class="card">
                                <div class="card-header" id="basicInfoHeading" aria-hidden="true" (click)="toggleBasicInfo()">
                                    <div class="d-flex align-items-center">
                                        <span class="badge-number">1</span>
                                        <h5 class="mb-0" style="margin-left: 10px">Basic Info</h5>
                                    </div>
                                </div>

                                <div *ngIf="showBasicInfo">
                                    <div class="card-body" style="margin-top: -15px">
                                        <div class="form-group">
                                            <label class="form-control-label" for="firstName" style="color: #a7a4a4; font-size: 12px"
                                                >*First Name</label
                                            >
                                            <input class="form-control rounded-3" id="firstName" type="text" formControlName="firstName" />
                                        </div>
                                        <div class="form-group" style="margin-top: -13px">
                                            <label class="form-control-label" for="lastName" style="color: #a7a4a4; font-size: 12px"
                                                >*Last Name</label
                                            >
                                            <input class="form-control rounded-3" id="lastName" type="text" formControlName="lastName" />
                                        </div>
                                        <div class="d-grid">
                                            <button
                                                type="button"
                                                class="btn btn-primary rounded-5"
                                                style="background-color: #fa914d; border: none"
                                                (click)="nextStep()"
                                            >
                                                NEXT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Contact Info -->
                            <div class="card">
                                <div class="card-header" id="contactInfoHeading" aria-hidden="true" (click)="toggleContactInfo()">
                                    <div class="d-flex align-items-center">
                                        <span class="badge-number">2</span>
                                        <h5 class="mb-0" style="margin-left: 10px">Contact Info</h5>
                                    </div>
                                </div>

                                <div *ngIf="showContactInfo">
                                    <div class="card-body" style="margin-top: -15px">
                                        <div class="form-group">
                                            <label class="form-control-label" for="mobileNumber" style="color: #a7a4a4; font-size: 12px"
                                                >*Mobile Number</label
                                            >
                                            <input
                                                class="form-control rounded-3"
                                                id="mobileNumber"
                                                type="text"
                                                formControlName="mobileNumber"
                                            />
                                        </div>
                                        <div class="form-group" style="margin-top: -13px">
                                            <label class="form-control-label" for="email" style="color: #a7a4a4; font-size: 12px"
                                                >*Email</label
                                            >
                                            <input class="form-control rounded-3" id="email" type="text" formControlName="email" />
                                        </div>
                                        <div class="form-group" style="margin-top: -13px">
                                            <label class="form-control-label" for="address" style="color: #a7a4a4; font-size: 12px"
                                                >*Address</label
                                            >
                                            <input class="form-control rounded-3" id="address" type="text" formControlName="address" />
                                        </div>
                                        <div class="d-grid">
                                            <button
                                                type="button"
                                                class="btn btn-primary rounded-5"
                                                style="background-color: #fa914d; border: none"
                                                (click)="nextStep()"
                                            >
                                                NEXT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Login Info -->
                            <div class="card">
                                <div class="card-header" id="loginInfoHeading" aria-hidden="true" (click)="toggleLoginInfo()">
                                    <div class="d-flex align-items-center">
                                        <span class="badge-number">3</span>
                                        <h5 class="mb-0" style="margin-left: 10px">Login Info</h5>
                                    </div>
                                </div>

                                <div *ngIf="showLoginInfo">
                                    <div class="card-body" style="margin-top: -15px">
                                        <div class="form-group">
                                            <label class="form-control-label" for="username" style="color: #a7a4a4; font-size: 12px"
                                                >*Username</label
                                            >
                                            <input class="form-control rounded-3" id="username" type="text" formControlName="username" />
                                        </div>
                                        <div class="form-group" style="margin-top: -13px">
                                            <label class="form-control-label" for="password" style="color: #a7a4a4; font-size: 12px"
                                                >*Password</label
                                            >
                                            <input
                                                class="form-control rounded-3"
                                                id="password"
                                                type="password"
                                                formControlName="password"
                                            />
                                        </div>
                                        <div class="d-grid">
                                            <button
                                                type="button"
                                                class="btn btn-primary rounded-5"
                                                style="background-color: #fa914d; border: none"
                                                (click)="submitForm()"
                                            >
                                                SUBMIT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
