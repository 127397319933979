import { Component } from '@angular/core';

@Component({
    selector: 'app-signup',
    standalone: true,
    imports: [],
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss'
})
export class SignupComponent {}
