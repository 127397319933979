<link rel="stylesheet" href="/aragon.css" />
<div style="background-image: url('assets/images/background.png')">
    <div class="container pb-5">
        <div class="row justify-content-center align-items-center vh-100 d-flex">
            <div class="col-lg-5 col-md-7">
                <div class="card border-0 mb-0" style="border-radius: 15px;">
                    <div class="card-body px-lg-5 rounded-2" style="background-color: #f7fafc">
                        <div class="text-center mb-4">
                            <span>
                                <img
                                    alt="Image placeholder"
                                    src="assets/images/ZSL.png"
                                    class="w-100"
                                    style="top: 230px; left: 580px; gap: 0px; opacity: 0px"
                                />
                            </span>
                        </div>
                        <div class="text-center mb-4">
                            <h4 style="color: #8898aa">Sign in with Credentials</h4>
                        </div>
                        <form role="form">
                            <div class="form-group mb-3">
                                <div
                                    class="input-group mb-3"
                                    style="
                                        box-shadow: 0px 1px 0px 0px #00000005;
                                        box-shadow: 0px 1px 3px 0px #32325d26;
                                    "
                                >
                                    <span class="input-group-text" style="background-color: white"
                                        ><i class="fa-sharp fa-solid fa-envelope" style="color: darkgray"></i
                                    ></span>

                                    <input class="form-control" placeholder="Email" type="email" name="userName" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div
                                    class="input-group mb-3"
                                    style="
                                        box-shadow: 0px 1px 0px 0px #00000005;
                                        box-shadow: 0px 1px 3px 0px #32325d26;
                                    "
                                >
                                    <span class="input-group-text" style="background-color: white"
                                        ><i class="fa-sharp fa-solid fa-unlock" style="color: darkgray"></i
                                    ></span>
                                    <input class="form-control" placeholder="Password" name="password" />
                                    <!-- <button class="btn btn-outline-light" type="button" (click)="togglePasswordVisibility()">
                                    <i
                                        class="fa-regular fa-eye"
                                        [ngClass]="{
                                            'fa-eye': !showPassword,
                                            'fa-eye-slash': showPassword
                                        }"
                                        style="color: rgb(23, 6, 6)"
                                    ></i>
                                </button> -->
                                </div>
                            </div>
                            <div class="custom-control custom-control-alternative custom-checkbox">
                                <input class="custom-control-input" id=" customCheckLogin" type="checkbox" />
                                <label class="custom-control-label" for=" customCheckLogin">
                                    <span class="px-1" style="color: #8898aa">Remember me</span>
                                </label>
                                <!-- <a [routerLink]="['/forgotpassword']" class="float-end text-black">
                                <small> Forgot password? </small>
                            </a> -->
                            </div>
                            <div class="text-center py-2">
                                <button
                                    type="button"
                                    class="btn btn-primary my-4 px-3"
                                    style="background-color: #fa914d; border: none"
                                    #signinInput
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <div class="position-absolute bottom-0 text-center">
            <a href="https://tedlance.com" class="font-weight-bold ml-1" target="_blank">Tedlance IT Solutions</a>
            &copy; {{ test | date : 'yyyy' }}
        </div> -->
        </div>
    </div>
</div>
