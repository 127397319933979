import { Component, AfterViewInit  } from '@angular/core';

@Component({
    selector: 'app-otp-verification',
    standalone: true,
    imports: [],
    templateUrl: './otp-verification.component.html',
    styleUrl: './otp-verification.component.scss'
})
export class OtpVerificationComponent implements AfterViewInit {
  // Lifecycle hook to enable the first OTP input field after view initialization
  ngAfterViewInit() {
      const otp1 = document.getElementById('otp1') as HTMLInputElement;
      if (otp1) {
          otp1.disabled = false;
      }
  }

  // Function to move to the next input field
  moveToNext(event: Event, nextFieldID: string) {
      const currentField = event.target as HTMLInputElement;
      if (currentField.value.length === 1) {
          const nextField = document.getElementById(nextFieldID) as HTMLInputElement;
          if (nextField) {
              nextField.disabled = false;
              nextField.focus();
          }
      }
  }
}