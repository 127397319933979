<link rel="stylesheet" href="/aragon.css" />
<div style="background-image: url('assets/images/background.png')">
    <div class="container pb-5">
        <div class="row justify-content-center align-items-center vh-100 d-flex">
            <div class="col-lg-5 col-md-7">
                <div class="card border-0 mb-0" style="border-radius: 15px">
                    <div class="card-body px-lg-5 rounded-2" style="background-color: #f7fafc">
                        <div class="text-center mb-4" style="margin-top: 60px">
                            <span>
                                <img alt="Image placeholder" src="assets/images/Vector.png" />
                            </span>
                        </div>
                        <div class="text-center mb-4">
                            <div style="line-height: 20px; font-size: 20px; color: #111111; font-weight: 700; margin-bottom: 11px">
                                Enter Code
                            </div>
                            <div style="line-height: 20px; font-size: 15px; color: #111111; font-weight: 400" class="py-1">
                                Your OTP code was sent to
                            </div>
                            <div style="line-height: 20px; font-size: 15px; color: #111111; font-weight: 500">(555) 867-5309</div>

                            <!-- OTP input box with black border -->
                            <div class="mt-3" style="display: flex; justify-content: center; align-items: center">
                                <input
                                    type="text"
                                    class="form-control otp-input"
                                    maxlength="1"
                                    style="width: 43px; text-align: center; border: 1px solid black; border-radius: 12px"
                                    (input)="moveToNext($event, 'otp2')"
                                    id="otp1"
                                />
                                <input
                                    type="text"
                                    class="form-control otp-input"
                                    maxlength="1"
                                    style="width: 43px; text-align: center; border: 1px solid black; border-radius: 12px; margin-left: 10px"
                                    (input)="moveToNext($event, 'otp3')"
                                    id="otp2"
                                    disabled
                                />
                                <input
                                    type="text"
                                    class="form-control otp-input"
                                    maxlength="1"
                                    style="width: 43px; text-align: center; border: 1px solid black; border-radius: 12px; margin-left: 10px"
                                    (input)="moveToNext($event, 'otp4')"
                                    id="otp3"
                                    disabled
                                />
                                <input
                                    type="text"
                                    class="form-control otp-input"
                                    maxlength="1"
                                    style="width: 43px; text-align: center; border: 1px solid black; border-radius: 12px; margin-left: 10px"
                                    (input)="moveToNext($event, 'otp5')"
                                    id="otp4"
                                    disabled
                                />
                                <input
                                    type="text"
                                    class="form-control otp-input"
                                    maxlength="1"
                                    style="width: 43px; text-align: center; border: 1px solid black; border-radius: 12px; margin-left: 10px"
                                    id="otp5"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="text-center mb-4">
                            <p style="color: #fa914d; font-size: 14px; font-weight: 600">
                                Didn’t receive a code?
                                <span style="color: #85c13d; font-size: 14px; font-weight: 600" class="text-underline">Send again</span>
                            </p>
                        </div>
                        <div class="d-grid" style="margin-top: 80px">
                            <button type="button" class="btn btn-primary rounded-5" style="background-color: #fa914d; border: none">
                                VERIFY
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
