import { Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { TeacherSignupComponent } from './teacher-signup/teacher-signup.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';

export const routes: Routes = [
   
    { path: '', component: SigninComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'teacher', component: TeacherSignupComponent },
    { path: 'otp', component: OtpVerificationComponent }
];
