<link rel="stylesheet" href="/aragon.css" />
<div style="background-image: url('assets/images/background.png')">
    <div class="container pb-5">
        <div class="row justify-content-center align-items-center vh-100 d-flex">
            <div class="col-lg-6 col-md-7">
                <div class="card border-0 mb-0" style="border-radius: 15px">
                    <div class="card-body px-lg-5 rounded-2" style="background-color: #f7fafc">
                        <div class="text-center mb-4">
                            <span>
                                <h3 style="color: #fa914d; font-weight: 700">Siginup</h3>
                            </span>
                        </div>

                        <form role="form" style="padding-top: 5px; padding-bottom: 20px">
                            <div class="card-footer">
                                <div class="d-flex align-items-center">
                                    <span class="badge-number">1</span>
                                    <p style="font-weight: 400; color: #333333; margin-left: 9px; padding-top: 14px">Subscription Type</p>
                                </div>

                                <div class="form-group" style="padding-top: 12px">
                                    <label class="form-control-label" for="name" style="color: #a7a4a4; font-size: 12px"
                                        >*Subscription Type</label
                                    >
                                    <input class="form-control rounded-3" id="name" type="text" formControlName="name" />
                                </div>
                                <div class="d-grid">
                                    <button
                                        type="button"
                                        class="btn btn-primary rounded-5"
                                        style="background-color: #fa914d; border: none"
                                        #signinInput
                                    >
                                        NEXT
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
