import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-teacher-signup',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './teacher-signup.component.html',
    styleUrl: './teacher-signup.component.scss'
})
export class TeacherSignupComponent {
  showBasicInfo: boolean = true;
  showContactInfo: boolean = false;
  showLoginInfo: boolean = false;
  currentStep: number = 1;
  progress: number = 33; // Initial progress for step 1
  toggleBasicInfo(): void {
    this.showBasicInfo = !this.showBasicInfo;
    if (this.showBasicInfo) {
      this.showContactInfo = false;
      this.showLoginInfo = false;
      this.currentStep = 1;
      this.progress = 33;
    }
  }

  toggleContactInfo(): void {
    this.showContactInfo = !this.showContactInfo;
    if (this.showContactInfo) {
      this.showBasicInfo = false;
      this.showLoginInfo = false;
      this.currentStep = 2;
      this.progress = 66;
    }
  }

  toggleLoginInfo(): void {
    this.showLoginInfo = !this.showLoginInfo;
    if (this.showLoginInfo) {
      this.showBasicInfo = false;
      this.showContactInfo = false;
      this.currentStep = 3;
      this.progress = 100;
    }
  }
  setStep(step: number): void {
    this.currentStep = step;
    this.updateProgress();

    this.showBasicInfo = step === 1;
    this.showContactInfo = step === 2;
    this.showLoginInfo = step === 3;
  }

  updateProgress(): void {
    if (this.currentStep === 1) {
      this.progress = 33;
    } else if (this.currentStep === 2) {
      this.progress = 66;
    } else if (this.currentStep === 3) {
      this.progress = 100;
    }
  }

  nextStep(): void {
    if (this.currentStep === 1) {
      this.setStep(2);
    } else if (this.currentStep === 2) {
      this.setStep(3);
    }
  }

  submitForm(): void {
    // Handle form submission
  }
}